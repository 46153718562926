<template>
  <div>
    <MealDetail v-if="showMealDetail" :mealId="mealId" :date="date" :dense="dense" :read-only="readOnly"/>
    <Plating v-if="showPlating" :mealId="mealId" :date="date" :read-only="readOnly"/>
  </div>
</template>

<script>
import MealDetail from '@/components/MealDetail';
import Plating from '@/components/Plating';

export default {
  name: "Meal",
  props: {
    readOnly: {type: Boolean, required: true},
    mealId: [Number, String],
    date: String,
    dense: Boolean
  },
  components: {
    MealDetail,
    Plating
  },
  computed:{
    showMealDetail() {
      return !(this.$route.query.plating && this.$route.query.plating==='1');
    },
    showPlating() {
      return this.$route.query.plating==='1';
    }
  }
}
</script>

<style scoped>

</style>
