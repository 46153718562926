import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[(!_vm.loading)?_c(VRow,_vm._l((_vm.mealIds),function(ref,index){
var mealId = ref.mealId;
var dietId = ref.dietId;
var tod = ref.tod;
return _c(VCol,{key:index,attrs:{"cols":_vm.columnsPerMeal}},[_c('h3',[_vm._v(_vm._s(_vm.getDietName(dietId))+" ("+_vm._s(_vm.getTimeOfDay(tod))+")")]),_c('Meal',{attrs:{"mealId":("" + mealId),"date":_vm.date,"dense":"","read-only":_vm.readOnly}})],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }